import React from 'react'
import { Button, Space, Spin } from 'antd';
import { RefSetCurrentPage } from '../hooks/NavigationHooks';
import * as locCon from "../LocalConstants"
import Title from 'antd/es/typography/Title';
import { exportParticipantsTable } from '../community-hats-js-library/utils/airtableFunctions';
import { StateStoredValue } from '../hooks/GeneralStoreHooks';
import * as libCon from '../community-hats-js-library/Constants';
import { showError } from '../utils/generalFunctions';


function DashboardSelect() {

  // Current Page
  const setCurrentPage = RefSetCurrentPage()

  const [downloadingParticipants, setDownloadingParticipants] = StateStoredValue(locCon.DOWNLOADING_PARTICIPANTS)


  const downloadParticipants = async () => {

    setDownloadingParticipants(true)
    let status = await exportParticipantsTable()
    if (status !== libCon.OK)
      showError(status)
    setDownloadingParticipants(false)



  }

  return (
    <div className="App">
      <header className="App-header">
        <Space direction="vertical">
          <Title level={2}>{`Deployment and Collection Monitoring`}</Title>
          <Button type="primary" onClick={() => setCurrentPage(locCon.PAGE_DASHBOARD_EXPIRY_PARTICIPANTS)}>Participant Data About to Expire View</Button>
          <Button type="primary" onClick={() => setCurrentPage(locCon.PAGE_DASHBOARD_DEPLOYMENT_STATUS)}>Participant Deployment Status View</Button>
          <Button type="primary" onClick={() => setCurrentPage(locCon.PAGE_DASHBOARD_COLLECTION_STATUS)}>Participant Collection Status View</Button>
          {/* <Title level={2}>{`Data Quality`}</Title>
          <Button type="primary" onClick={() => setCurrentPage(locCon.PAGE_DASHBOARD_DATA_STREAM_QUALITY)}>Data Quality Streams View</Button> */}
          <Title level={2}>{`Overall Progress`}</Title>
          <Button type="primary" onClick={() => setCurrentPage(locCon.PAGE_DASHBOARD_DEPLOYMENT_PROGRESS)}>Deployment Progress View</Button>
          {/* <Button type="primary" onClick={() => setCurrentPage(locCon.PAGE_DASHBOARD_LAB_UPLOADS)}>Lab Uploads</Button>*/}

          <Title level={2}>{`Download`}</Title>
          {
            downloadingParticipants
              ? <Spin />
              : <Button type="primary" onClick={() => downloadParticipants()}>Download Current Participants Table</Button>
          }


        </Space>

      </header>
    </div>
  )
}

export default DashboardSelect