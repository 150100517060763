import React, { useState } from 'react';
import Title from 'antd/es/typography/Title';
import { processPdf, splitPdf } from '../community-hats-js-library/utils/labFunctions';

import { Upload, Button, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";

function DashboardUploadLabResults() {

    const [pdfFiles, setPdfFiles] = useState([]);

    const [file, setFile] = useState(null);

    // Function to handle file processing
    const processFile = async () => {
        if (!file) {
            message.error("No file selected!");
            return;
        }


        const pdfBytes = await file.arrayBuffer();

        // Split the PDF
        const splitPdfs = await splitPdf(pdfBytes);
        const precessedFiles = await Promise.all(splitPdfs.map(async p => await processPdf(p, "123456789")))


        setPdfFiles(precessedFiles);
    };


    return (
        <div className="mainContainer" >
            <Title level={1}>{`Lab Uploads`}</Title>
            <Upload
                beforeUpload={(uploadedFile) => {
                    setFile(uploadedFile); // Store the uploaded file
                    message.success(`File selected: ${uploadedFile.name}`);
                    return false; // Prevent automatic upload
                }}
                showUploadList={false} // Hide default file list
            >
                <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>

            {/* Display file name */}
            {file && <p style={{ marginTop: "10px" }}>Selected File: {file.name}</p>}

            {/* Process File Button */}
            <Button
                type="primary"
                style={{ marginTop: "10px" }}
                onClick={processFile}
            >
                Process File
            </Button>

            {pdfFiles.map((file, index) => (
                <a
                    key={index}
                    href={URL.createObjectURL(new Blob([file], { type: 'application/pdf' }))}
                    download={`split_${index + 1}.pdf`}
                >
                    Download PDF {index + 1}
                </a>
            ))}

        </div>
    )
}

export default DashboardUploadLabResults