import React from 'react'
import { Radio, Space } from 'antd';
import * as locCon from "../LocalConstants"
import { StateStoredValue } from '../hooks/GeneralStoreHooks';


function Header() {

    const [userType, setUserType] = StateStoredValue(locCon.USER_TYPE)

    return (
        <div className='horizontalSectionRight' style={{ marginTop: "10px", marginRight: "15px" }}>
            <Radio.Group onChange={(e) => setUserType(e.target.value)} value={userType}>
                <Space direction="horizontal">
                    <Radio style={{ color: "white", fontSize: "14px" }} value={locCon.USER_TYPE_SEWA}>Sewa</Radio>
                    <Radio style={{ color: "white", fontSize: "14px" }} value={locCon.USER_TYPE_OUTSIDE_COLLABORATOR}>Outside Collaborator</Radio>
                </Space>
            </Radio.Group>
        </div>
    )
}

export default Header