// Library Constants

import config_dev from "./config/public_config_development.json"
import config_prod from "./config/public_config_production.json"
import latestPerceptualSurvey from "../community-hats-js-library/assets/perceptual_survey_configuration.json"
import latestParticipantIntakeForm from "../community-hats-js-library/assets/participant_intake_survey_configuration.json"
import latestHouseIntakeForm from "../community-hats-js-library/assets/house_intake_survey_configuration.json"
import CryptoJS from 'crypto-js';


// Development
export const DEVELOPMENT = false


export const CONFIG_ENCTYPTED = DEVELOPMENT ? config_dev : config_prod


function decryptValue(encryptedString, key) {
    const [ivHex, encryptedDataHex] = encryptedString.split(':');

    // Convert IV and key into WordArray format
    const iv = CryptoJS.enc.Hex.parse(ivHex);
    const keyWordArray = CryptoJS.enc.Hex.parse(key);

    // Decrypt the data
    const decrypted = CryptoJS.AES.decrypt(
        { ciphertext: CryptoJS.enc.Hex.parse(encryptedDataHex) },
        keyWordArray,
        {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        }
    );

    // Convert decrypted data to a readable UTF-8 string
    return CryptoJS.enc.Utf8.stringify(decrypted);
}



export const CONFIG = async (key) => {

    // first gets the key
    const response = await fetch('/.netlify/functions/getSecret');
    const data = await response.json();

    return decryptValue(CONFIG_ENCTYPTED[key], data.key)

}


// Latest perceptual survey
export const LATEST_PERCEPTUAL_SURVEY = latestPerceptualSurvey

// Latest Intake Form
export const LATEST_PARTICIPANT_INTAKE_FORM = latestParticipantIntakeForm
export const LATEST_HOUSE_INTAKE_FORM = latestHouseIntakeForm

// Password
export const PASSWORD_HASH = "password_hash"

// Map Constants
export const MAP_KEY = "map_key"

// AirTable Constants
export const AT_API_KEY = "airtable_key"
export const AT_APP_ID = "at_app_id"

// AWS Constants
export const AWS_REGION = "aws_region"
export const AWS_BUCKET = "aws_sensors_bucket"
export const AWS_KEY = "aws_access_key"
export const AWS_SECRET = "aws_secret_key"


// Fitbit API
export const FITBIT_FINAL_APPLICATION_ID = "fitbit_app_id"
export const FITBIT_API_SEP = "---"
// Defaults
export const DEFAULT_PHONE_NUMBER = "0000000000"


// Accuracy
export const IDEAL_ACCURACY = 15

// Sensor Inbox
export const SENSOR_INBOX_EMAIL = "sensor_inbox_email"

// Intake Form
export const INTAKE_FORM_LINK = "https://docs.google.com/forms/d/e/1FAIpQLSflKny18QvRLvcE-kGeb3KHR3hqUk6y8Ng0GHGe16crnMEXcA/viewform"
export const INTAKE_FORM_FIELD_ID_NAME = "entry.63757476"
export const INTAKE_FORM_FIELD_ID_LAST_NAME = "entry.658433398"
export const INTAKE_FORM_FIELD_ID_SEWA_ID = "entry.761365968"

// Capacities
export const INPIRE_3_CAPACITY = 30
export const GOVEE_CAPACITY = 20
export const KESTREL_CAPACITY = 20
export const HOBO_CAPACITY = 180




// Status
export const STATUS_DATASET_PARTICIPANTS = "STATUS_DATASET_PARTICIPANTS"
export const STATUS_DATASET_SENSORS = "STATUS_DATASET_SENSORS"
export const STATUS_DATASET_HOUSES = "STATUS_DATASET_HOUSES"
export const STATUS_DATASET_EMAILS = "STATUS_DATASET_EMAILS"
export const STATUS_DATASET_PARTICIPANT_PLACEMENT = "STATUS_DATASET_PARTICIPANT_PLACEMENT"
export const STATUS_DATASET_SENSOR_HOUSE_PLACEMENT = "STATUS_DATASET_SENSOR_HOUSE_PLACEMENT"
export const STATUS_DATASET_WEARABLES = "STATUS_DATASET_WEARABLES"
export const STATUS_DATASET_PHONES = "STATUS_DATASET_PHONES"
export const STATUS_DATASET_WEARABLE_PLACEMENT = "STATUS_DATASET_WEARABLE_PLACEMENT"
export const STATUS_DATASET_PHONE_PLACEMENT = "STATUS_DATASET_PHONE_PLACEMENT"
export const STATUS_DATASET_RECEIVED_SENSOR_FILES = "STATUS_DATASET_RECEIVED_SENSOR_FILES"
export const STATUS_DATASET_ISSUES = "STATUS_DATASET_ISSUES"



// Datasets
export const DATASET_PARTICIPANTS = "DATASET_PARTICIPANTS"
export const DATASET_SENSORS = "DATASET_SENSORS"
export const DATASET_HOUSES = "DATASET_HOUSES"
export const DATASET_EMAILS = "DATASET_EMAILS"
export const DATASET_WEARABLES = "DATASET_WEARABLES"
export const DATASET_PHONES = "DATASET_PHONES"
export const DATASET_PARTICIPANT_PLACEMENT = "DATASET_PARTICIPANT_PLACEMENT"
export const DATASET_WEARABLE_PLACEMENT = "DATASET_WEARABLE_PLACEMENT"
export const DATASET_PHONE_PLACEMENT = "DATASET_PHONE_PLACEMENT"
export const DATASET_SENSOR_HOUSE_PLACEMENT = "DATASET_SENSOR_HOUSE_PLACEMENT"
export const DATASET_RECEIVED_SENSOR_FILES = "DATASET_RECEIVED_SENSOR_FILES"
export const DATASET_ISSUES = "DATASET_ISSUES"




// General Contants
export const ID = "id"
export const VALUE = "value"
export const ERROR = "error"
export const LOADING = "loading"
export const OK = "ok"
export const KEY = "key"
export const DATE = "date"
export const YES = "Yes"
export const NO = "No"
export const NA = "NA"
export const MISSING = "MISSING"
export const START_DATE = "start_date"
export const END_DATE = "end_date"
export const DURATION = "duration"
export const COVERAGE = "coverage"
export const INFO = "info"
export const TEXT = "text"
export const LINK = "link"
export const CONTAINER = "container"
export const STATUS = "status"
export const FIELDS = "fields"
export const POSITION_ID = "position_id"
export const OBJECT = "object"
export const LOADING_INFO = "loading_info"
export const DONE = "done"
export const MESSAGE = "message"
export const TABLE_CONFIG_KEY = "table_config_key"
export const NECESSARY_IDS = "necessary_ids"
export const OPTIONAL_IDS = "optional_ids"
export const COMPUTE_ON_UPLOAD = "compute_on_upload"
export const DERIVATE_IDS = "derivate_ids"
export const SINGLE_DEPENDENCIES = "single_dependencies"
export const SET_DEPENDENCIES = "array_dependencies"
export const IS_INSIDE_SET = "is_inside_array"
export const SEARCH = "search"
export const CODE = "code"

// Status
export const STATUS_OK = "STATUS_OK"
export const STATUS_LOADING = "STATUS_LOADING"
export const STATUS_NOT_STARTED = "STATUS_NOT_STARTED"
export const STATUS_NOT_FOUND = "STATUS_NOT_FOUND"
export const STATUS_ERROR = "STATUS_ERROR"


// Sensor Brands
export const KESTREL = "Kestrel"
export const GOVEE = "Govee"
export const HOBO = "Hobo"
// Models
export const U12_HOBO_MODEL = "U12-013"
export const MX_HOBO_MODEL = "MX1104"

// Tools
export const DASHBOARD = "Dashboard"
export const CH_MANAGERE_APP = "CH Manager"



// Languages
export const EN = "en"
export const GU = "gu"
export const ES = "es"
export const HI = "hi"



export const ATF_ADDRESSVATION_CODE = "Activation Code"
export const ATF_DATE_CREATED = "Date Created"
export const ATF_SERIAL = "Serial"
export const ATF_PUBLIC_ID = "ID"
export const ATF_ADDRESS = "Address"
export const ATF_AREA = "Area"
export const ATF_SENSOR = "Sensor"
export const ATF_PHONE = "Phone"
export const ATF_WEARABLE = "Wearable"
export const ATF_LOCATION = "Location"
export const ATF_LATITUDE = "Latitude"
export const ATF_LONGITUDE = "Longitude"
export const ATF_ACCURACY = "Accuracy"
export const ATF_LAST_UPLOADED = "Last Uploaded"
export const ATF_LAST_MIGRATION = "Last Migration"
export const ATF_LATEST_RECORD = "Latest Record"
export const ATF_BRAND = "Brand"
export const ATF_MODEL = "Model"
export const ATF_UPLOADED_DATE = "Date Uploaded"
export const ATF_START_DATE = "Start Date"
export const ATF_END_DATE = "End Date"
export const ATF_TAG_COLOR = "Tag Color"
export const ATF_TAG_CODE = "Tag Code"
export const ATF_SEWA_ID = "SEWA Id"
export const ATF_TYPE = "Type"
export const ATF_SENSOR_PLACEMENTS = "Sensor Placements"
export const ATF_SENSOR_PLACEMENT = "Sensor Placement"
export const ATF_PHONE_PLACEMENTS = "Phone Placement"
export const ATF_WEARABLE_PLACEMENT = "Wearable Placement"
export const ATF_HAS_ACTIVE_SENSORS = "Has Active Sensors"
export const ATF_ACTIVE_SENSORS = "Active Sensors"
export const ATF_TOTAL_ACTIVE_SENSORS = "Total Active Sensors"
export const ATF_HAS_ACTIVE_WEARABLES = "Has Active Wearables"
export const ATF_ACTIVE_WEARABLE = "Active Wearable"
export const ATF_HAS_ACTIVE_EMAILS = "Has Active Account Email"
export const ATF_ACTIVE_EMAIL = "Active Account Email"
export const ATF_HAS_ACTIVE_PHONES = "Has Active Phones"
export const ATF_ACTIVE_PHONE = "Active Phone"
export const ATF_RECEIVED_SENSOR_FILES = "Received Sensor Files"
export const ATF_NAME = "Name"
export const ATF_LAST_NAME = "Last Name"
export const ATF_PHONE_NUMBER = "Phone Number"
export const ATF_NOTES = "Notes"
export const ATF_DAYS_UNTIL_EXPIRE = "Days Until Expire"
export const ATF_DAYS_SINCE_LAST_HOUSE_VISIT = "Days Since Last House Visit"
export const ATF_LATEST_COLLECTION_DATE = "Latest Collection Date"
export const ATF_DATA_START = "Data Start"
export const ATF_ACTIVE = "Active"
export const ATF_JOINED_DATE = "Joined Date"
export const ATF_DATE_UPLOADED = "Date Uploaded"
export const ATF_SIZE_IN_DAYS = "Size in Days"
export const ATF_FITBIT_TOKEN = "Fitbit Token"
export const ATF_FITBIT_REQUEST_ID = "Fitbit Request ID"
export const ATF_EMAIL = "Email"
export const ATF_ACCOUNT_EMAIL = "Account Email"
export const ATF_DEVICE_COLLECTION_STATUS = "Device Collection Status"
export const ATF_DEVICE_COLLECTION_MESSAGE = "Device Collection Message"
export const ATF_LATEST_BATTERY_CHANGE = "Latest Battery Change"
export const ATF_STATUS = "Status"
export const ATF_PENDING = "Pending"
export const ATF_DONE = "Done"
export const ATF_READY_FOR_REVISION = "Ready for Revision"
export const ATF_ISSUES = "Issues"
export const ATF_RESPONSIBLE = "Responsible"
export const ATF_GROUND_TEAM_ISSUES = "Ground Team Issues"
export const ATF_GROUND_TEAM = "Ground Team"
export const ATF_NUMERIC_ID = "Numeric ID"
export const ATF_TAGS = "Tags"
export const ATF_DESCRIPTION = "Description"
export const ATF_DESCRIPTION_GU = "Description Gu"
export const ATF_DESCRIPTION_ES = "Description Es"
export const ATF_DESCRIPTION_HI = "Description Hi"
export const ATF_ACTION = "Action"
export const ATF_ACTION_GU = "Action Gu"
export const ATF_ACTION_ES = "Action Es"
export const ATF_ACTION_HI = "Action Hi"
export const ATF_DATE_SOLVED = "Date Solved"
export const ATF_PERCEPTUAL_STATUS = "Perceptual Status"
export const ATF_REQUIRED = "Required"
export const ATF_COMPLETE = "Complete"
export const ATF_INTAKE_SURVEY = "Intake Form"
export const ATF_PERCEPTUAL_SURVEY = "Perceptual Survey"
export const ATF_LAST_UPLOADED_PERCEPTUAL_SURVEY = "Last Uploaded (Perceptual Survey)"
export const ATF_LAST_UPLOADED_WEARABLE = "Last Uploaded (Wearable)"
export const ATF_PASSWORD = "Password"
export const ATF_DROPBOX_PASSWORD = "Dropbox Password"
export const ATF_GOVEE_PASSWORD = "Govee Password"
export const ATF_ID_NAME = "id"
export const ATF_PHONE_SERIAL = "Phone Serial"
export const ATF_PLACEMENT_START = "Placement Start Date"
export const ATF_FITBIT_CLIENT_ID = "Fitbit Client ID"
export const ATF_FITBIT_TOKEN_EXPIRY_DATE = "Fitbit Token Expiry Date"
export const ATF_PARTICIPANT = "Participant"
export const ATF_HOUSE = "House"
export const ATF_SENSORS = "Sensors"
export const ATF_PLACEMENT_PHOTO = "Placement Photo"
export const ATF_POSITION = "Position"
export const ATF_LOCATION_NUMERIC_ID = "Location Numeric ID"
export const ATF_SENSOR_SERIAL = "Sensor Serial"
export const ATF_ACTIVE_HOUSE = "Active House"
export const ATF_ACTIVE_ACCOUNT_EMAIL = "Active Account Email"
export const ATF_PARTICIPANT_PLACEMENT = "Participant Placement"
export const ATF_DEVELOPMENT = "Development"
export const ATF_INCOMPLETE = "Incomplete"
export const ATF_DURATION = "Duration"
export const ATF_DATE_OF_COLLECTION = "Date of Collection"
export const ATF_TRADE = "Trade"
export const ATF_HOMEBASE_WORKER = "Homebase Worker"
export const ATF_AGRICULTURE = "Agriculture"
export const ATF_AHMEDABAD = "Ahmedabad"
export const ATF_ANAND = "Anand"
export const ATF_ACTIVATION_CODE = "Activation Code"
export const ATF_INTAKE_FORM_STATUS = "Intake Form Status"
export const ATF_INTAKE_FORM_DATE = "Intake Form Date"
export const ATF_PARTICIPANT_INTAKE_FORM = "Participant Intake Form"
export const ATF_HOUSE_INTAKE_FORM = "House Intake Form"
export const ATF_ALL_INTAKE_FORMS = "All Intake Forms"
export const ATF_LATEST_HEART_RATE_UPLOAD = "Latest Heart Rate Upload"
export const ATF_ACTIVE_PLACEMENT = "Active Placement"




export const TRADES = [ATF_HOMEBASE_WORKER, ATF_AGRICULTURE]
export const AREAS = [ATF_AHMEDABAD, ATF_ANAND]




// Status and Messages
export const AT_SENSORS_COLLECTION_STATUS = "Sensors Collection Status"
export const AT_SENSORS_COLLECTION_MESSAGE = "Sensors Collection Message"
export const AT_COLLECTION_STATUS = "Collection Status"
export const AT_COLLECTION_MESSAGE = "Collection Message"



// Airtable Tables
export const AT_TABLE_GOVEE_ACTIVATION_CODES_ID = "at_govee_activation_codes_id"
export const AT_TABLE_EMAILS = "at_emails"
export const AT_TABLE_PARTICIPANTS = "at_participants"
export const AT_TABLE_PHONES = "at_phones"
export const AT_TABLE_PHONE_PLACEMENTS = "at_phone_placements"
export const AT_TABLE_WEARABLE_PLACEMENTS = "at_wearable_placements"
export const AT_TABLE_WEARABLES = "at_wearables"
export const AT_TABLE_HOUSES = "at_houses"
export const AT_TABLE_PARTICIPANTS_PLACEMENT = "at_participant_placement"
export const AT_TABLE_SENSORS = "at_sensors"
export const AT_TABLE_SENSOR_HOUSE_PLACEMENT = "at_sensor_house_placement"
export const AT_TABLE_SENSOR_PLACEMENT_FORM = "at_sensor_placement_form"
export const AT_TABLE_RECEIVED_SENSOR_FILES = "at_received_sensor_files"
export const AT_TABLE_ISSUES = "at_issues"
export const AT_TABLE_BUNDLES = "at_bundles"
export const AT_TABLE_COLLECTION_RECORDS = "at_collection_records"


// Views
export const AT_VIEW_PARTICIPANTS_PUBLIC = "Public"


// Devices
export const DEVICE_SENSORS = "Sensors"
export const DEVICE_PHONE = "Location Tracker"
export const DEVICE_WEARABLE = "Wearable"


// Device Status
export const DEVICE_STATUS_OK = "DEVICE_STATUS_OK"
export const DEVICE_STATUS_WAITING_COLLECTION = "DEVICE_STATUS_WAITING_COLLECTION"
export const DEVICE_STATUS_WAITING_COLLECTION_LOOSING_DATA = "DEVICE_STATUS_WAITING_COLLECTION_LOOSING_DATA"
export const DEVICE_STATUS_ERROR_IN_COLLECTION = "DEVICE_STATUS_ERROR_IN_COLLECTION"
export const DEVICE_STATUS_HARDWARE_MALFUNCTION = "DEVICE_STATUS_HARDWARE_MALFUNCTION"
export const DEVICE_STATUS_INCOMPLETE_DEPLOYMENT = "DEVICE_STATUS_INCOMPLETE_DEPLOYMENT"
export const DEVICE_STATUS_OTHER = "DEVICE_STATUS_OTHER"



// Messages
export const MESSAGE_DATA_UP_TO_DATE = "messageDataUpToDate";
export const MESSAGE_PLACEMENT_JUST_STARTED = "messagePlacementJustStarted";
export const MESSAGE_BATTERY_CHANGE_JUST_HAPPENED = "messageBatteryChangeJustHappened";
export const MESSAGE_WAITING_FOR_COLLECTION = "messageWaitingForCollection";
export const MESSAGE_WAITING_FOR_COLLECTION_LOOSING_DATA = "messageWaitingForCollectionLoosingData";
export const MESSAGE_DATA_WAS_NOT_COLLECTED = "messageDataWasNotCollected";
export const MESSAGE_NO_FILES_HAVE_BEEN_RECEIVED = "messageNoFilesHaveBeenReceived";
export const MESSAGE_SENSOR_FILE_NOT_RECENT_ENOUGH = "messageSensorFileNotRecentEnough";
export const MESSAGE_SENSOR_FILE_NOT_LONG_ENOUGH = "messageSensorFileNotLongEnough";
export const MESSAGE_NO_SENSORS_IN_BUNDLE = "messageNoSensorsInBundle";
export const MESSAGE_INCOMPLETE_DEPLOYMENT = "messageIncompleteDeployment";
export const MESSAGE_ERROR_IN_COLLECTION = "messageErrorInCollection";
export const MESSAGE_HANDLED_BY_CEPT = "messageHandledByCEPT";
export const MESSAGE_MULTIPLE_INTAKES = "messageMultipleIntakes";
export const MESSAGE_NO_INTAKES = "messageNoIntakes";
export const MESSAGE_INTAKE_COMPLETE = "messageIntakeComplete";
export const MESSAGE_INTAKE_WAITING = "messageIntakeWaiting";
export const MESSAGE_PLEASE_REFRESH = "messagePleaseRefresh"
export const MESSAGE_STILL_HAS_PENDING_ISSUES = "messageStillHasPendingIssues"
export const MESSAGE_NO_ISSUES = "messageNoIssues"
export const MESSAGE_HEART_RATE_NOT_RECENT = "heartRateNotRecent"



export const MESSAGES_FOR_STATUS = {
    [MESSAGE_DATA_UP_TO_DATE]: "Data up to date",
    [MESSAGE_PLACEMENT_JUST_STARTED]: "Data up to date (Placement just started, no data expected yet)",
    [MESSAGE_BATTERY_CHANGE_JUST_HAPPENED]: "Data up to date (Batteries recently updated, no extensive data expected yet)",
    [MESSAGE_WAITING_FOR_COLLECTION]: "Waiting for Collection",
    [MESSAGE_WAITING_FOR_COLLECTION_LOOSING_DATA]: "Waiting for Collection (Loosing Data)",
    [MESSAGE_DATA_WAS_NOT_COLLECTED]: "Data was not Collected",
    [MESSAGE_NO_FILES_HAVE_BEEN_RECEIVED]: "No files have been received",
    [MESSAGE_SENSOR_FILE_NOT_RECENT_ENOUGH]: "Latest files not recent enough, please select a more recent end date for the export, or make sure the sensor has synched completely.",
    [MESSAGE_SENSOR_FILE_NOT_LONG_ENOUGH]: "Latest files not long enough, please select an earlier start date",
    [MESSAGE_NO_SENSORS_IN_BUNDLE]: "No sensors in bundle.",
    [MESSAGE_INCOMPLETE_DEPLOYMENT]: "Incomplete Deployment",
    [MESSAGE_ERROR_IN_COLLECTION]: "Error in collection",
    [MESSAGE_HANDLED_BY_CEPT]: "Collection is Handled by the CEPT Team",
    [MESSAGE_MULTIPLE_INTAKES]: "The participant has multiple active placements.",
    [MESSAGE_NO_INTAKES]: "The participant has no active placements.",
    [MESSAGE_INTAKE_COMPLETE]: "Intake Form up to date.",
    [MESSAGE_INTAKE_WAITING]: "Please administer the intake form.",
    [MESSAGE_PLEASE_REFRESH]: "Please refresh",
    [MESSAGE_STILL_HAS_PENDING_ISSUES]: "Participant still has pending issues",
    [MESSAGE_NO_ISSUES]: "No pending issues",
    [MESSAGE_HEART_RATE_NOT_RECENT]: "Heart Rate not recent enough, please make sure heart rate synching."
};


export const dashboardTranslation = (m) => m in MESSAGES_FOR_STATUS ? MESSAGES_FOR_STATUS[m] : m


// Tag Colors
export const TAG_COLORS = ["gold", "magenta", "blue", "lime", "volcano", "geekblue", "red", "green", "cyan", "orange", "purple"]



// Airtable Object Sync Button Bheaviors
export const ATO_BEHAVIOR_PULL = "pull"
export const ATO_BEHAVIOR_CREATE = "create"
export const ATO_BEHAVIOR_UPDATE = "update"
export const ATO_BEHAVIOR_UPDATE_CREATE = "update_create"
export const ATO_BEHAVIOR_PULL_CREATE = "pull_create"
export const ATO_BEHAVIOR_SYNC = "sync"


export const COLORS = ["Green", "Yellow", "Red", "Pink", "Gray", "Orange", "White", "Blue", "Violet", "Brown"]

export const SENSOR_POSITIONS = ["Both Sleep and Work", "Workplace", "Sleep Room", "Kitchen", "Other"]


export const NETWORK_ERROR = "netrwork_error"
export const AIRTABLE_ERROR = "airtable_error"
export const NOT_FOUND_ERROR = "not_found_error"
export const UNOTHORIZED_ERROR = "unothorized_error"



export const ERRORS = {
    [ERROR]: {
        [MESSAGE]: "generalError",
        [CODE]: 1
    },
    [NETWORK_ERROR]: {
        [MESSAGE]: "networkError",
        [CODE]: 2
    },
    [AIRTABLE_ERROR]: {
        [MESSAGE]: "airtableError",
        [CODE]: 3
    },
    [NOT_FOUND_ERROR]: {
        [MESSAGE]: "notFoundError",
        [CODE]: 4
    },
    [UNOTHORIZED_ERROR]: {
        [MESSAGE]: "unothorizedError",
        [CODE]: 5
    }


}


// Likert
export const LIKERT_NUMERIC = "numeric"
export const LIKERT_STRING = "text"
export const DEFAULT_LIKERT = { [LIKERT_NUMERIC]: -1, [LIKERT_STRING]: NA }


// Perceptual Survey
export const PERCEPTUAL_SURVEY_FOLDER = "perceptual_survey"

export const SURVEY_IMAGES_FOLDER = "survey_images"


// Intake Form
export const PARTICIPANT_INTAKE_FORM_FOLDER = 'participant_intake_form'
export const HOUSE_INTAKE_FORM_FOLDER = 'house_intake_form'


// Configuration 
export const SURVEY_STRUCTURE = "structure"
export const SURVEY_METADATA = "metadata"
export const SURVEY_VERSION = "version"

// Surveys
export const PERCEPTUAL_SURVEY_PREFIX = "__HF__"
export const PARTICIPANT_INTAKE_FORM_PREFIX = "__PIF__"
export const HOUSE_INTAKE_FORM_PREFIX = "__HIF__"




// Question Constants 
export const SURVEY_QUESTION_ID = "id"
export const SURVEY_QUESTION_TYPE = "type"
export const SURVEY_QUESTION_TITLE = "title"
export const SURVEY_QUESTION_INFO_TEXT = "infoText"
export const SURVEY_QUESTION_TRANSLATION = "translation"
export const SURVEY_QUESTION_IMAGE = "image"
export const SURVEY_QUESTION_PRECISION = "precision"
export const SURVEY_QUESTION_INCLUDE_NA = "include_na"
export const SURVEY_QUESTION_MIN_VALUE = "min_value"
export const SURVEY_QUESTION_MAX_VALUE = "max_value"





// Types
export const SURVEY_QUESTION_TYPE_MULTIPLE_CHOICE = "multiple_choice"
export const SURVEY_QUESTION_TYPE_MULTIPLE_CHOICE_IMAGES = "multiple_choice_images"
export const SURVEY_QUESTION_TYPE_MULTIPLE_CHOICE_AND_ANSWER = "multiple_choice_answer"
export const SURVEY_QUESTION_TYPE_BINARY = "binary"
export const SURVEY_QUESTION_TYPE_NUMERIC = "numeric"
export const SURVEY_QUESTION_TYPE_CUSTOM = "custom"
export const SURVEY_QUESTION_TYPE_IMAGE_UPLOAD = 'image_upload'
export const SURVEY_QUESTION_TYPE_TEXT_AREA = 'text_area'



// Multiple Choice constants
export const SURVEY_MULTIPLE_CHOICE_OPTIONS = "options"
export const SURVEY_MULTIPLE_CHOICE_IMAGES = "images"
export const SURVEY_MULTIPLE_CHOICE_ANSWER_TYPE = "answer_type"
export const SURVEY_MULTIPLE_CHOICE_INCLUDE_OTHER = "include_other"
export const SURVEY_MULTIPLE_CHOICE_REMOVE_NONE = "remove_none"
export const SURVEY_MULTIPLE_CHOICE_NONE_TEXT = "none_text"



// Binary Constants
export const SURVEY_BINARY_OPTION_YES = "yes"
export const SURVEY_BINARY_OPTION_NO = "no"

// HealthForm Export constants
export const SURVEY_EXPORT_CONFIGURATION = "configuration"
export const SURVEY_EXPORT_ANSWERS = "answers"
export const SURVEY_EXPORT_PARTICIPANT_ID = "id"
export const SURVEY_EXPORT_SEWA_ID = "sewa_id"
export const SURVEY_EXPORT_PHONE_SERIAL = "phone_serial"
export const SURVEY_EXPORT_DATETIME = "datetime"
export const SURVEY_EXPORT_LOCALE = "locale"
export const SURVEY_EXPORT_HOUSE_NUMBER = "house_number"
export const SURVEY_EXPORT_HOUSE_ID = "house_id"






// Custom types
// Blood Pressure
export const HF_CUSTOM_BLOOD_PRESSURE = "blood_pressure"

export const HEALTH_FORM_SYSTOLIC_BLOOD_PRESSURE_1 = "systolic_blood_pressure"
export const HEALTH_FORM_DIASTOLIC_BLOOD_PRESSURE_1 = "diastolic_blood_pressure"
export const HEALTH_FORM_SYSTOLIC_BLOOD_PRESSURE_2 = "systolic_blood_pressure_2"
export const HEALTH_FORM_DIASTOLIC_BLOOD_PRESSURE_2 = "diastolic_blood_pressure_2"
export const HEALTH_FORM_SYSTOLIC_BLOOD_PRESSURE_3 = "systolic_blood_pressure_3"
export const HEALTH_FORM_DIASTOLIC_BLOOD_PRESSURE_3 = "diastolic_blood_pressure_3"

export const HF_BLOOD_PRESSURE_DEFAULT_ANSWER = {
    [HEALTH_FORM_SYSTOLIC_BLOOD_PRESSURE_1]: NA,
    [HEALTH_FORM_DIASTOLIC_BLOOD_PRESSURE_1]: NA,
    [HEALTH_FORM_DIASTOLIC_BLOOD_PRESSURE_1]: NA,
    [HEALTH_FORM_SYSTOLIC_BLOOD_PRESSURE_2]: NA,
    [HEALTH_FORM_DIASTOLIC_BLOOD_PRESSURE_2]: NA,
    [HEALTH_FORM_SYSTOLIC_BLOOD_PRESSURE_3]: NA,
    [HEALTH_FORM_DIASTOLIC_BLOOD_PRESSURE_3]: NA,
}


// Oral Temperature
export const HF_CUSTOM_ORAL_TEMPERATURE = "oral_temperature"
export const HF_ORAL_TEMPERATURE_1 = "oral_temperature"
export const HF_ORAL_TEMPERATURE_2 = "oral_temperature_2"
export const HF_ORAL_TEMPERATURE_3 = "oral_temperature_3"


export const HF_TEMPERATURE_DEFAULT_ANSWER = {
    [HF_ORAL_TEMPERATURE_1]: NA,
    [HF_ORAL_TEMPERATURE_2]: NA,
    [HF_ORAL_TEMPERATURE_3]: NA,
}


// Blood Glucose Level
export const HF_CUSTOM_BLOOD_GLUCOSE_LEVEL = "blood_glucose_level"




