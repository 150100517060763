import React, { useEffect, useReducer, useRef, useState } from 'react'
import { Button, Progress, Switch, Tag } from 'antd';
import * as libCon from "../community-hats-js-library/Constants"
import * as locCon from "../LocalConstants"
import * as dm_fun from "../utils/dataManipulationFunctions"
import { BarChart, Bar, XAxis, YAxis, Tooltip, Rectangle, CartesianGrid, Line, LineChart, ResponsiveContainer } from 'recharts';
import { add, format, differenceInCalendarDays, addDays } from "date-fns";
import { RefAllParticipantsWithPlacement, RefAllSensorsWithPlacement, refreshAllDatasets } from '../hooks/DatasetHooks';
import Title from 'antd/es/typography/Title';
import LoadingDatasets from '../components/LoadingDatasets';
import maplibregl from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';
import { isNullOrUndefined } from '../community-hats-js-library/utils/generalFunctions';
import { getSensorBrandColor } from '../utils/generalFunctions';



const CustomBarChart = ({ chartData, domain, ticks, fill = 'var(--chart-color-6)', axisColor = 'var(--text-color)' }) => {

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`${format(label, 'yyyy-MM-dd')}`}</p>
          <p className="number">{`Participants Deployed: ${payload[0].value}`}</p>
        </div>
      );
    }

    return null;
  };

  return (
    <div style={{ width: "100%", height: "27vh" }}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart width={1200} height={200} data={chartData}>
          <CartesianGrid strokeDasharray="3" />
          <XAxis dataKey={locCon.DATE} tickFormatter={dateFormatter} domain={domain} ticks={ticks} tick={{ fontSize: 14, fill: axisColor }} />
          <YAxis tick={{ fontSize: 14, fill: axisColor }} />
          <Bar dataKey={locCon.VALUE} fill={fill} activeBar={<Rectangle fill="red" stroke="white" />} />
          <Tooltip content={<CustomTooltip />} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}



const CustomLineChart = ({ chartData, domain, ticks, stroke = 'var(--chart-color-6)', axisColor = 'var(--text-color)' }) => {

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`${format(label, 'yyyy-MM-dd')}`}</p>
          <p className="number">{`Sensors Deployed: ${payload[0].value}`}</p>
        </div>
      );
    }

    return null;
  };

  return (
    <div style={{ width: "100%", height: "27vh" }}>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart width={1400} height={200} data={chartData}>
          <CartesianGrid strokeDasharray="3" />
          <XAxis dataKey={locCon.DATE} tickFormatter={dateFormatter} domain={domain} ticks={ticks} tick={{ fontSize: 14, fill: axisColor }} />
          <YAxis tick={{ fontSize: 14, fill: axisColor }} />
          <Line type="monotone" dataKey={locCon.CUMULATIVE_VALUE} stroke={stroke} fill={stroke} />
          <Tooltip content={<CustomTooltip />} />
        </LineChart>
      </ResponsiveContainer>
    </div>
  )
}


export const CustomMap = ({ sensors }) => {

  const mapContainer = useRef(null);
  const map = useRef(null);
  const lng = 72.575;
  const lat = 23.024;
  const zoom = 11;


  useEffect(() => {
    //if (map.current) return; // stops map from intializing more than once

    const updateMap = async () => {


      const API_KEY = await libCon.CONFIG(libCon.MAP_KEY);

      map.current = new maplibregl.Map({
        container: mapContainer.current,
        style: `https://api.maptiler.com/maps/streets-v2/style.json?key=${API_KEY}`,
        center: [lng, lat],
        zoom: zoom
      });


      if (!isNullOrUndefined(sensors)) {
        let coordinates = dm_fun.extractCoordinatesFromActivePlacements(sensors)

        coordinates.forEach(coor => {

          let color = getSensorBrandColor(coor[2])

          new maplibregl.Marker({ color: color, scale: 0.7 })
            .setLngLat([coor[1], coor[0]])
            .addTo(map.current);
        }
        )

      }

    }

    updateMap()


  }, [lng, lat, zoom, sensors]);


  return (
    <div ref={mapContainer} className="map" />

  )

}


const dateFormatter = date => {
  return format(new Date(date), "dd/MMM");
};

const getTicks = (startDate, endDate, num) => {
  const diffDays = differenceInCalendarDays(endDate, startDate);

  let current = startDate,
    velocity = Math.round(diffDays / (num - 1));

  const ticks = [startDate.getTime()];

  for (let i = 1; i < num - 1; i++) {
    ticks.push(add(current, { days: i * velocity }).getTime());
  }

  ticks.push(endDate.getTime());
  return ticks;
};


// Reducer constants
const CHART_DATA = "chart_data"
const FILTERED_CHART_DATA = "filtered_chart_data"
const DOMAIN = "domain"
const TICKS = "ticks"
const TOTAL = "total"

// Actions
const UPDATE_DATA = "UPDATE_DATA"

// Charts
const PARTICIPANTS = "PARTICIPANTS"
const SENSORS = "SENSORS"



const initialChartData = {
  [CHART_DATA]: null,
  [FILTERED_CHART_DATA]: null,
  [DOMAIN]: null,
  [TICKS]: null,
  [TOTAL]: "--"
}

const initialState = {
  [PARTICIPANTS]: { ...initialChartData },
  [SENSORS]: { ...initialChartData },

}





function DashboardDeploymentProgress() {


  // Participants
  const [statusParticipants, participants] = RefAllParticipantsWithPlacement()

  // Sensors
  const [statusSensors, sensors] = RefAllSensorsWithPlacement()


  const [isCumulative, setIsCumulative] = useState(true)


  const reducer = (state, action) => {

    switch (action.type) {
      case UPDATE_DATA:


        const minDate = new Date('2024-05-01').getTime();

        // Participants
        let participantDeploymentData = dm_fun.computeParticipantsByDate(participants)

        participantDeploymentData = participantDeploymentData.filter(ob => ob[locCon.DATE] >= minDate)

        let totalDates = participantDeploymentData.length - 1
        const filteredParticipantDeploymentData = participantDeploymentData.filter((pd, i) => (i === 0) || (i === totalDates) || pd[locCon.CUMULATIVE_VALUE] > participantDeploymentData[i - 1][locCon.CUMULATIVE_VALUE])

        let start_date = participantDeploymentData[0][locCon.DATE]
        let end_date = addDays(new Date(), 1).getTime();

        state = {
          ...state,
          [PARTICIPANTS]: {
            [CHART_DATA]: participantDeploymentData,
            [FILTERED_CHART_DATA]: filteredParticipantDeploymentData,
            [DOMAIN]: [start_date, end_date],
            [TICKS]: getTicks(new Date(start_date), new Date(end_date), 8),
            [TOTAL]: participantDeploymentData[participantDeploymentData.length - 1][locCon.CUMULATIVE_VALUE]
          }
        }

        // Sensors
        let sensorDeploymentData = dm_fun.computeSensorsByDate(sensors)

        sensorDeploymentData = sensorDeploymentData.filter(ob => ob[locCon.DATE] >= minDate)


        totalDates = sensorDeploymentData.length - 1
        const filteredSensorDeploymentData = sensorDeploymentData.filter((pd, i) => (i === 0) || (i === totalDates) || pd[locCon.CUMULATIVE_VALUE] > sensorDeploymentData[i - 1][locCon.CUMULATIVE_VALUE])

        start_date = sensorDeploymentData[0][locCon.DATE]
        end_date = addDays(new Date(), 1).getTime();

        state = {
          ...state,
          [SENSORS]: {
            [CHART_DATA]: sensorDeploymentData,
            [FILTERED_CHART_DATA]: filteredSensorDeploymentData,
            [DOMAIN]: [start_date, end_date],
            [TICKS]: getTicks(new Date(start_date), new Date(end_date), 8),
            [TOTAL]: sensorDeploymentData[sensorDeploymentData.length - 1][locCon.CUMULATIVE_VALUE]
          }

        }


        return state
      default:
        return state;
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState);


  useEffect(() => {

    if (participants !== null && statusParticipants === libCon.OK
      && sensors !== null && statusSensors === libCon.OK
    )
      dispatch({ type: UPDATE_DATA });

  }, [participants, statusParticipants, sensors, statusSensors])




  const DailyView = () => {
    return (
      <div>
        <Title level={4} style={{ marginTop: 0 }}>{`Participants Deployed (Daily)`}</Title>
        <CustomBarChart chartData={state[PARTICIPANTS][CHART_DATA]} domain={state[PARTICIPANTS][DOMAIN]} ticks={state[PARTICIPANTS][TICKS]} fill='var(--chart-color-6)' />
        <Title level={4}>{`Sensors Deployed (Daily)`}</Title>
        <CustomBarChart chartData={state[SENSORS][CHART_DATA]} domain={state[SENSORS][DOMAIN]} ticks={state[SENSORS][TICKS]} fill='var(--chart-color-5)' />
      </div >
    )
  }

  const CumulativeView = () => {
    return (
      <div>
        <Title level={4} style={{ marginTop: 0 }}>{`Participants Deployed (Cumulative)`}</Title>
        <CustomLineChart chartData={state[PARTICIPANTS][CHART_DATA]} domain={state[PARTICIPANTS][DOMAIN]} ticks={state[PARTICIPANTS][TICKS]} stroke='var(--chart-color-6)' />
        <Title level={4}>{`Sensors Deployed (Cumulative)`}</Title>
        <CustomLineChart chartData={state[SENSORS][CHART_DATA]} domain={state[SENSORS][DOMAIN]} ticks={state[SENSORS][TICKS]} stroke='var(--chart-color-5)' />
      </div>
    )
  }




  return (
    <div className="mainContainer" >
      <Title level={1}>{`Deployment Progress`}</Title>
      <Button type="primary" onClick={() => refreshAllDatasets()}>Refresh</Button>
      <Title level={3} style={{ marginBottom: 0 }}>{`Total Deployed Participants: ${state[PARTICIPANTS][TOTAL]} of ${locCon.TARGET_PARTICIPANTS}`}</Title>

      {
        statusParticipants === libCon.STATUS_NOT_STARTED || statusParticipants === libCon.LOADING || state[CHART_DATA] === null
          ? <div>
            <LoadingDatasets />
          </div>
          : statusParticipants === libCon.ERROR
            ? <div>Error</div>
            : <>
              <div style={{ width: "80%", marginBottom: "1vh" }} >
                <Progress percent={Math.round(100 * (state[PARTICIPANTS][TOTAL] / locCon.TARGET_PARTICIPANTS))} strokeColor={"var(--chart-color-2)"} style={{ fontSize: "30px", color: "white" }} />
              </div>
              <Switch checkedChildren="Cummulative" unCheckedChildren="Daily Values" checked={isCumulative} onChange={(b, e) => setIsCumulative(b)} />
              <div className='horizontalSection' style={{ width: "100vw", marginTop: "1vh" }}>
                <div style={{ width: "48%", marginLeft: "1%", marginRight: "1%" }}>
                  {
                    isCumulative
                      ? <CumulativeView />
                      : <DailyView />
                  }
                </div>
                <div style={{ width: "45%", marginLeft: "1%", marginRight: "1%", height: "63vh", }}>
                  <div className='horizontalSection' style={{ marginBottom: "1vh" }}>
                    <Tag color={getSensorBrandColor(libCon.GOVEE)} style={{ fontSize: 25 }}>{libCon.GOVEE}</Tag>
                    <Tag color={getSensorBrandColor(libCon.KESTREL)} style={{ fontSize: 25 }}>{libCon.KESTREL}</Tag>
                    <Tag color={getSensorBrandColor(libCon.HOBO)} style={{ fontSize: 25 }}>{libCon.HOBO}</Tag>

                  </div>
                  <CustomMap sensors={sensors} />

                </div>


              </div>

            </>

      }

    </div>


  )
}

export default DashboardDeploymentProgress

