import { PDFDocument, rgb } from 'pdf-lib';
import * as libCon from '../Constants';
// import { getDocument } from 'pdfjs-dist';
import * as pdfjsLib from 'pdfjs-dist/webpack';
import { isNullOrUndefined } from './generalFunctions';

export async function splitPdf(pdfBytes) {

    const pdfSize = 3

    const originalPdf = await PDFDocument.load(pdfBytes);

    const pdfs = [];

    let bufferPfs = [];

    let newPdfBytes, newPage

    let currentPDF = await PDFDocument.create();

    for (let i = 0; i < originalPdf.getPageCount(); i++) {

        if (i % pdfSize === 0 && bufferPfs.length > 0) {

            newPdfBytes = await currentPDF.save();
            pdfs.push(newPdfBytes); // Each PDF file as Uint8Array

            bufferPfs = []
            currentPDF = await PDFDocument.create();

        }

        [newPage] = await currentPDF.copyPages(originalPdf, [i]);
        currentPDF.addPage(newPage)
        bufferPfs.push(newPage)


    }

    // Final Document
    if (bufferPfs.length > 0) {
        newPdfBytes = await currentPDF.save();
        pdfs.push(newPdfBytes); // Each PDF file as Uint8Array
    }


    return pdfs; // Array of PDFs split by page
}

export async function extractAllText(pdfBytes) {
    try {
        // Load the PDF document
        const pdfDocument = await pdfjsLib.getDocument({ data: pdfBytes }).promise;

        let fullText = [];

        // Loop through each page
        for (let i = 1; i <= pdfDocument.numPages; i++) {
            const page = await pdfDocument.getPage(i);
            const textContent = await page.getTextContent();

            // Extract text from the page
            const pageText = textContent.items
                .map((item) => item.str);

            fullText = fullText.concat(pageText); // Add a newline after each page
        }

        // Cleans
        fullText = fullText.map(p => p.replace(" ", ""))
        fullText = fullText.filter(p => p !== "")



        return fullText;
    } catch (error) {
        console.error('Error extracting text from PDF:', error);
        throw error;
    }
}

export function extractMetadata(textArray) {

    let i = -1;
    let response = {}

    // Name
    let name = null
    i = textArray.indexOf("Name")
    if (i >= 0) {
        // Colon
        if (i + 1 < textArray.length && textArray[i + 1] === ":")
            i += 1

        if (i + 1 < textArray.length)
            name = textArray[i + 1]
    }
    response[libCon.ATF_NAME] = name


    // CaseId
    let caseId = null
    i = textArray.indexOf("CaseID")
    if (i >= 0) {
        // Colon
        if (i + 1 < textArray.length && textArray[i + 1] === ":")
            i += 1

        if (i + 1 < textArray.length)
            caseId = textArray[i + 1]
    }

    response[libCon.ATF_CASE_ID] = caseId


    // Age
    let age = null
    i = textArray.indexOf("Sex/Age")
    if (i >= 0) {
        i += 3
        if (i + 1 < textArray.length) {
            age = textArray[i + 1]
            age = parseInt(age.replace("Years", ''))

            if (isNullOrUndefined(age))
                age = null
        }

    }
    response[libCon.ATF_AGE] = age


    // Phone Number
    let phoneNumber = null
    i = textArray.indexOf("MobileNo.")
    if (i >= 0) {
        // Colon
        if (i + 1 < textArray.length && textArray[i + 1] === ":")
            i += 1

        if (i + 1 < textArray.length) {
            phoneNumber = parseInt(textArray[i + 1])
            if (isNullOrUndefined(phoneNumber))
                phoneNumber = null

        }
    }
    response[libCon.ATF_PHONE_NUMBER] = phoneNumber


    return response
}


export async function processPdf(pdfBytes, replacement) {


    // Step 1: Load the PDF with pdfjs-dist and extract text
    // const pdf = await getDocument({ data: pdfBytes }).promise;

    // let textPositions = [];

    // for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
    //     const page = pdf.getPage(pageNum);

    //     console.log(page)
    //     const textContent = await page.getTextContent();

    //     textContent.items.forEach((item) => {
    //         if (item.str.includes(`Name`)) {
    //             textPositions.push({
    //                 page: pageNum - 1, // pdf-lib uses 0-based index for pages
    //                 x: item.transform[4], // x-coordinate
    //                 y: item.transform[5], // y-coordinate
    //             });
    //         }
    //     });
    // }

    // if (textPositions.length === 0) {
    //     console.log("No matching text found in the PDF.");
    //     return null;
    // }


    // Step 2: Modify the PDF with pdf-lib
    const pdfDoc = await PDFDocument.load(pdfBytes);
    const pages = pdfDoc.getPages();

    pages.forEach((page) => {

        // Name
        // Rectangle
        page.drawRectangle({
            x: 96,
            y: 720,
            width: 174,
            height: 14,
            color: rgb(1, 1, 1), // White color
        });
        // Overlay text
        page.drawText("000000000000000000000000", {
            x: 100,
            y: 723,
            size: 13, // Adjust the font size as needed
            color: rgb(1, 1, 1), // Black color
        });

        page.drawText(replacement.toString(), {
            x: 100,
            y: 723,
            size: 13, // Adjust the font size as needed
            color: rgb(0, 0, 0), // Black color
        });

        // Phone Number
        page.drawRectangle({
            x: 502,
            y: 673,
            width: 80,
            height: 14,
            color: rgb(1, 1, 1), // White color
        });
        // Overlay text
        page.drawText("00000000000", {
            x: 502,
            y: 676,
            size: 13, // Adjust the font size as needed
            color: rgb(1, 1, 1), // Black color
        });

    });

    // Step 3: Save the modified PDF
    const modifiedPdfBytes = await pdfDoc.save();
    return modifiedPdfBytes;
}


