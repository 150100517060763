import React, { useState } from 'react';
import Title from 'antd/es/typography/Title';
import { extractAllText, extractMetadata, splitPdf } from '../community-hats-js-library/utils/labFunctions';
import jaroWinkler from 'jaro-winkler';

import { Upload, Button, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { RefParticipantsData } from '../hooks/DatasetHooks';
import * as libCon from "../community-hats-js-library/Constants"
import { isNullOrUndefined } from '../community-hats-js-library/utils/generalFunctions';
import { exportToExcel } from '../community-hats-js-library/utils/ExcelFunctions';
import { format } from 'date-fns';


function DashboardUploadLabResults() {


    const [participantStatus, participants] = RefParticipantsData()

    const [pdfFiles, setPdfFiles] = useState([]);

    const [file, setFile] = useState(null);

    // Function to handle file processing
    const processFile = async () => {
        if (!file) {
            message.error("No file selected!");
            return;
        }



        // DELETE
        if (participantStatus !== libCon.OK)
            return


        const pdfBytes = await file.arrayBuffer();

        // Split the PDF
        const pdfArray = await splitPdf(pdfBytes);
        const texts = await Promise.all(pdfArray.map(async p => await extractAllText(new Uint8Array(p))))
        const metadataArray = texts.map(t => extractMetadata(t))


        // iterates 
        metadataArray.forEach(metadata => {
            if (isNullOrUndefined(metadata[libCon.ATF_NAME]))
                return


            let scoreMax = -1
            let score = null
            let name = null
            let last_name = null
            let target_name = null
            let target_phone = null
            Object.values(participants).forEach(p => {

                name = p[libCon.ATF_NAME].toLowerCase()
                name = name.replace("ben", "")
                name = name.replace(" ", "")

                last_name = p[libCon.ATF_LAST_NAME]
                last_name = last_name.toLowerCase()

                target_name = `${name} ${last_name}`
                target_phone = undefined
                if (libCon.ATF_PHONE_NUMBER in p)
                    target_phone = p[libCon.ATF_PHONE_NUMBER].replace(/[()\-\s]/g, '');
                score = jaroWinkler(target_name, metadata[libCon.ATF_NAME].toLowerCase());
                if (score > scoreMax) {
                    scoreMax = score
                    metadata[libCon.ATF_TARGET_COMPLETE_NAME] = target_name
                    metadata[libCon.ATF_TARGET_SEWA_ID] = p[libCon.ATF_SEWA_ID]
                    metadata[libCon.ATF_NAME_SCORE] = score
                    metadata[libCon.ATF_TARGET_PHONE_NUMBER] = target_phone
                    metadata[libCon.ATF_PHONE_NUMBER_MATCH] = target_phone === `${metadata[libCon.ATF_PHONE_NUMBER]}` ? libCon.YES : libCon.NO

                }

            })

        })

        // converts to metadata dict
        let metadataDict = {}
        let pdfDicts = {}
        metadataArray.forEach((metadata, i) => {
            metadataDict[metadata[libCon.ATF_CASE_ID]] = metadata
            pdfDicts[metadata[libCon.ATF_CASE_ID]] = pdfArray[i]
        })

        // Creates the excel for download and manual process
        const now = new Date()
        exportToExcel(Object.values(metadataArray), `Lab Results ${format(now, "yyyy-MM-d")}.xlsx`, [libCon.ATF_CASE_ID, libCon.ATF_AGE, libCon.ATF_PHONE_NUMBER, libCon.ATF_NAME, libCon.ATF_TARGET_COMPLETE_NAME, libCon.ATF_TARGET_PHONE_NUMBER, libCon.ATF_PHONE_NUMBER_MATCH, libCon.ATF_TARGET_SEWA_ID, libCon.ATF_NAME_SCORE])



        // const processedFiles = await Promise.all(splitPdfs.map(async p => await processPdf(p, "123456789")))

        setPdfFiles(pdfArray);
    };


    return (
        <div className="mainContainer" >
            <Title level={1}>{`Lab Uploads`}</Title>
            <Upload
                beforeUpload={(uploadedFile) => {
                    setFile(uploadedFile); // Store the uploaded file
                    message.success(`File selected: ${uploadedFile.name}`);
                    return false; // Prevent automatic upload
                }}
                showUploadList={false} // Hide default file list
            >
                <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>

            {/* Display file name */}
            {file && <p style={{ marginTop: "10px" }}>Selected File: {file.name}</p>}

            {/* Process File Button */}
            <Button
                type="primary"
                style={{ marginTop: "10px" }}
                onClick={processFile}
            >
                Process File
            </Button>

            {pdfFiles.map((file, index) => (
                <a
                    key={index}
                    href={URL.createObjectURL(new Blob([file], { type: 'application/pdf' }))}
                    download={`split_${index + 1}.pdf`}
                >
                    Download PDF {index + 1}
                </a>
            ))}

        </div>
    )
}

export default DashboardUploadLabResults