import { PDFDocument, rgb } from 'pdf-lib';
// import { getDocument } from 'pdfjs-dist';


export async function splitPdf(pdfBytes) {

    const pdfSize = 3

    const originalPdf = await PDFDocument.load(pdfBytes);

    const pdfs = [];

    let bufferPfs = [];

    let newPdfBytes, newPage

    let currentPDF = await PDFDocument.create();

    for (let i = 0; i < originalPdf.getPageCount(); i++) {

        if (i % pdfSize === 0 && bufferPfs.length > 0) {

            newPdfBytes = await currentPDF.save();
            pdfs.push(newPdfBytes); // Each PDF file as Uint8Array

            bufferPfs = []
            currentPDF = await PDFDocument.create();

        }

        [newPage] = await currentPDF.copyPages(originalPdf, [i]);
        currentPDF.addPage(newPage)
        bufferPfs.push(newPage)


    }

    // Final Document
    if (bufferPfs.length > 0) {
        newPdfBytes = await currentPDF.save();
        pdfs.push(newPdfBytes); // Each PDF file as Uint8Array
    }


    return pdfs; // Array of PDFs split by page
}



export async function processPdf(pdfBytes, replacement) {


    // Step 1: Load the PDF with pdfjs-dist and extract text
    // const pdf = await getDocument({ data: pdfBytes }).promise;

    // let textPositions = [];

    // for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
    //     const page = pdf.getPage(pageNum);

    //     console.log(page)
    //     const textContent = await page.getTextContent();

    //     textContent.items.forEach((item) => {
    //         if (item.str.includes(`Name`)) {
    //             textPositions.push({
    //                 page: pageNum - 1, // pdf-lib uses 0-based index for pages
    //                 x: item.transform[4], // x-coordinate
    //                 y: item.transform[5], // y-coordinate
    //             });
    //         }
    //     });
    // }

    // if (textPositions.length === 0) {
    //     console.log("No matching text found in the PDF.");
    //     return null;
    // }


    // Step 2: Modify the PDF with pdf-lib
    const pdfDoc = await PDFDocument.load(pdfBytes);
    const pages = pdfDoc.getPages();

    pages.forEach((page) => {

        // Name
        // Rectangle
        page.drawRectangle({
            x: 96,
            y: 720,
            width: 174,
            height: 14,
            color: rgb(1, 1, 1), // White color
        });
        // Overlay text
        page.drawText("000000000000000000000000", {
            x: 100,
            y: 723,
            size: 13, // Adjust the font size as needed
            color: rgb(1, 1, 1), // Black color
        });

        page.drawText(replacement.toString(), {
            x: 100,
            y: 723,
            size: 13, // Adjust the font size as needed
            color: rgb(0, 0, 0), // Black color
        });

        // Phone Number
        page.drawRectangle({
            x: 502,
            y: 673,
            width: 80,
            height: 14,
            color: rgb(1, 1, 1), // White color
        });
        // Overlay text
        page.drawText("00000000000", {
            x: 502,
            y: 676,
            size: 13, // Adjust the font size as needed
            color: rgb(1, 1, 1), // Black color
        });

    });

    // Step 3: Save the modified PDF
    const modifiedPdfBytes = await pdfDoc.save();
    return modifiedPdfBytes;
}


